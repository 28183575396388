// src/components/LoadingOverlay.js
import React from 'react';
import { useSelector } from 'react-redux';
import './LoadingOverlay.css';
import logo from '../assets/logo.gif';
const LoadingOverlay = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  console.log('LoadingOverlay rendered. isLoading:', isLoading);
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner">  <img src={logo} alt="Logo" className="logo-popup" /></div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
