import React, { useState, useEffect } from "react";
import "./ManageSMS.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch} from 'react-redux';
import { showLoading, hideLoading } from '../redux/slices/loadingSlice';
const ManageSMS = () => {
  const [formData, setFormData] = useState({
    count: "",
    credit: "",
    sentDate: "",
  });
  const [messageMetrics, setMessageMetrics] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);

   const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDate) {
        dispatch(showLoading());
      const token = localStorage.getItem("accessToken");

      const loadingToast = toast.loading("Fetching message metrics...");

      axios
        .get(`${process.env.REACT_APP_BASE_URL}messagemetrics?date=${selectedDate}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setMessageMetrics(response.data);
          if (response.data.length > 0) {
            const metric = response.data[0];
            setFormData({
              count: metric.count.toString(),
              credit: metric.credit.toString(),
              sentDate: metric.sentDate.split("T")[0],
            });
          } else {
            setFormData({ count: "", credit: "", sentDate: selectedDate });
          }
          toast.success("Message metrics fetched successfully!", {
            id: loadingToast,
            style: { background: "#ecfdf5", color: "#047857" },
          });
        })
        .catch((error) => {
          console.error("Error fetching message metrics:", error);
          toast.error("Failed to fetch message metrics.", {
            id: loadingToast,
            style: { background: "#fef2f2", color: "#b91c1c" },
          });
        })
        .finally(() => {
          setTimeout(() => dispatch(hideLoading()), 2000); 
        });
    }
  }, [selectedDate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    const { count, credit, sentDate } = formData;
    if (!count || !credit || !sentDate) {
      toast.error("Please fill in all fields", {
        style: { background: "#fef2f2", color: "#b91c1c" },
      });
      return;
    }
    const token = localStorage.getItem("accessToken");
    dispatch(showLoading());
    toast.loading("Saving message metrics...");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}messagemetrics`,
        {
          id: 1,
          messageTypeId: 1,
          count: parseInt(count, 10),
          credit: parseFloat(credit),
          sentDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        toast.dismiss();
        toast.success("Message metrics saved successfully!", {
          style: { background: "#ecfdf5", color: "#047857" },
        });
        setSelectedDate(sentDate);
      })
      .catch((error) => {
        console.error("Error saving message metrics:", error);
        toast.dismiss();
        toast.error("Failed to save message metrics.", {
          style: { background: "#fef2f2", color: "#b91c1c" },
        });
      })
      .finally(() => {
        setTimeout(() => dispatch(hideLoading()), 2000); 
      });
  };

  const handleDiscard = () => {
    dispatch(showLoading());
    setTimeout(() => {
    setFormData({ count: "", credit: "", sentDate: "" });
    toast.success("Form cleared successfully!", {
      style: { background: "#ecfdf5", color: "#047857" },
    });
    dispatch(hideLoading());
    }, 2000);
  };

  return (
    <div id="priceoutercont">
      <div id="rtmprice">RTM Manage SMS</div>
      <div id="realtime">
        <Toaster position="top-center" />
        Easily manage, edit or delete your existing RTM message reports. Stay in
        charge of your preferences with seamless SMS management.
      </div>
      <div id="manage-template-outer-cont">
        <div className="add-missed">Add Missed Calls</div>
        <div className="date-out-cont">
          <div className="select-out-cont">
            <form>
              <div className="select-date-out">
                <label>Select a Date</label>
                <div className="select-date-cont">
                  <input
                    type="date"
                    name="sentDate"
                    value={formData.sentDate}
                    onChange={(e) => {
                      handleInputChange(e);
                      setSelectedDate(e.target.value);
                    }}
                    className="input-field"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="select-out-cont">
            <div className="select-date-out">
              <label>Count</label>
              <div className="select-date-cont">
                <input
                  type="number"
                  name="count"
                  value={formData.count}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </div>
            </div>
          </div>
          <div className="select-out-cont">
            <div className="select-date-out">
              <label>Credit Per Message SMS</label>
              <div className="select-date-cont">
                <input
                  type="number"
                  name="credit"
                  value={formData.credit}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="discard-save-out-cont">
          <button className="save-button-sms" onClick={handleSave}>
            Save
          </button>
          <button className="discard-button-sms" onClick={handleDiscard}>
            Discard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageSMS;
