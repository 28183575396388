import React, { useState, useRef } from 'react';
import './NewTemplate.css';
import EditOutline from './picture/edit-outline.svg';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useDispatch} from 'react-redux';
import { showLoading, hideLoading } from '../redux/slices/loadingSlice';
const NewTemplate = () => {
  const [templateId, setTemplateId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [smsTemplate, setSmsTemplate] = useState('');
 const dispatch = useDispatch();
  const templateIdRef = useRef(null);
  const templateNameRef = useRef(null);
  const smsTemplateRef = useRef(null);

  const validateForm = () => {
    let isValid = true;

    if (!templateId.trim()) {
      toast.error('Template ID is required', {
        style: { background: '#fef2f2', color: '#b91c1c' },
      });
      isValid = false;
    }
    if (!templateName.trim()) {
      toast.error('Template Name is required', {
        style: { background: '#fef2f2', color: '#b91c1c' },
      });
      isValid = false;
    }
    if (!smsTemplate.trim()) {
      toast.error('SMS Template cannot be empty', {
        style: { background: '#fef2f2', color: '#b91c1c' },
      });
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const payload = {
        id: 0, 
        clientId: 1,
        templateId: templateId,
        name: templateName,
        message: smsTemplate,
        deletedStatus: false,
        activeStatus: false,
      };

      try {
        const token = localStorage.getItem("accessToken");
       
        dispatch(showLoading())
         
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}template`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Response:', response.data);

        toast.success('Template successfully saved!', {
          style: { background: '#ecfdf5', color: '#047857' },
        });

        setTemplateId('');
        setTemplateName('');
        setSmsTemplate('');
      } catch (error) {
        console.error('Error saving template:', error);
        toast.error('Failed to save template. Please try again.', {
          style: { background: '#fef2f2', color: '#b91c1c' },
        });
      }
    } else {
      console.log('Form validation failed');
    }
    setTimeout(() => {
     dispatch(hideLoading())
    }, 2000); 
  };

  const handleCancel = () => {
    setTemplateId('');
    setTemplateName('');
    setSmsTemplate('');
    toast('ℹ️ Form cleared', {
      icon: 'ℹ️',
      style: { background: '#eef2ff', color: '#4338ca' },
    });
  };

  const handleEditClick = (inputRef) => {
    inputRef.current.focus();
  };

  return (
    <div id="priceoutercont">
      <div id="rtmprice">RTM New Template</div>
      <div id="realtime">
        Stay informed with real-time rates of gold, silver, and platinum. Customize your SMS notifications for seamless updates.
      </div>
      <div id="smswhole-newtem">
        <div className="form-group">
          <label>
            Template ID
            <div className="edit-icon-outer" onClick={() => handleEditClick(templateIdRef)}>
            </div>
          </label>
          <input
            type="text"
            value={templateId}
            onChange={(e) => setTemplateId(e.target.value)}
            placeholder="Enter a new template ID"
            ref={templateIdRef}
          />
        </div>
        <div className="form-group">
          <label>
            Template Name
            <div className="edit-icon-outer" onClick={() => handleEditClick(templateNameRef)}>
            </div>
          </label>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Enter a new template name"
            ref={templateNameRef}
          />
        </div>
        <div className="form-group">
          <label>
            SMS Template
            <div className="edit-icon-outer" onClick={() => handleEditClick(smsTemplateRef)}>
            </div>
          </label>
          <textarea
            placeholder="SMS Template"
            value={smsTemplate}
            onChange={(e) => setSmsTemplate(e.target.value)}
            ref={smsTemplateRef}
            rows={10}
            style={{ width: '100%' }}
          ></textarea>
        </div>
        <div className="sms-button">
          <button className="save-btn" onClick={handleSubmit}>
            Create
          </button>
          <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
        </div>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontFamily: 'Lato, sans-serif',
            borderRadius: '8px',
          },
        }}
      />
    </div>
  );
};

export default NewTemplate;
