import React from 'react';
import './Footer.css';
import footerlogo from './assets/footerlogo.png'; 


const Footer = () => {
  return (
    <footer>
     <div className="footer-container">
      <h4>Copyright © 2024 Ram Thanganagai Maligai | Powered by <span style={{ color: '#F50052',marginRight:'05px' }} >Thofa</span>
       <span style={{ color: '#07A048' }} >Technologies</span></h4>
       <h4 className="powered-by">
          <a href="/privacy-policy">Privacy Policy</a> | 
          <a href="/terms-and-conditions"> Terms and Conditions</a>
        </h4>
      
      </div>  
    </footer>   
  );
}

export default Footer;
