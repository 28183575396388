
import React from 'react';
import './Home.css';
import Body from './Body';

const Home = () => {
  return (
    <div className="home-container">
      <div className="body-container">
      </div>
    </div>
  );
}

export default Home;
