import React, { useState, useEffect } from "react";
import "./PriceUpdates.css";
import data_icon from "./priceimage/date_icon.png";
import calender_icon from "./priceimage/calender_icon.png";
import goldicon from "./priceimage/gold_icon.png";
import silvericon from "./priceimage/silver_icon.png";
import platinumicon from "./priceimage/platinum_icon.png";
import valueedit from "./priceimage/valueedit.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch} from 'react-redux';
import { showLoading, hideLoading } from '../redux/slices/loadingSlice';
const PriceUpdates = () => {
     const dispatch = useDispatch();
  const [goldRate, setGoldRate] = useState("");
  const [silverRate, setSilverRate] = useState("");
  const [platinumRate, setPlatinumRate] = useState("");
  const [editableRate, setEditableRate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState("");
  const [previousData, setPreviousData] = useState({
    date: "",
    gold: "",
    silver: "",
    platinum: "",
    previousDate: "",
    previousGold: "",
    previousSilver: "",
    previousPlatinum: "",
    previousTemplate: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [messageType, setMessageType] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);


const handleSuccess = () => {
  setSuccessMessage(true);
  //alert("Price updated successfully!")
  toast.success("Price updated successfully!", {
    duration: 6000, 
    position: "bottom-center", 
  });
    setMessageType('success');
    setMessage('Price updated successfully.');
    //setShowModal(true);
  };
 
  
  
  const processTemplate = (template, date, gold, silver, platinum) => {     
    if (!template) 
      { 
        return ''; 
      }
    return template
      .replace("{#Date#}", date.toLocaleDateString("en-GB"))
      .replace("{#Gold#}", gold)
      .replace("{#Silver#}", silver)
      .replace("{#Platinum#}", platinum);
  };

  const handleError = (message) => {
    toast.error(message);
    setMessageType('error');
    setMessage(message);
    setShowModal(true);
  };

  //const closeModal = () => {
   // setShowModal(false);
  //};

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const storedTemplate = localStorage.getItem("getresponseTemplate");
  const storedPreviousData = localStorage.getItem("previousData");
    if (!token) {
      toast.error("Access token not found in localStorage");
      if (storedTemplate) setTemplate(storedTemplate);
    if (storedPreviousData) setPreviousData(JSON.parse(storedPreviousData));
      return;
    }
    dispatch(showLoading());
    //  dispatch(showLoading());
    fetch(`${process.env.REACT_APP_BASE_URL}prices/getpriceupdate`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        
        const gold = data.productPriceDetails.find((item) => item.name === "Gold")?.price || "";
        const silver = data.productPriceDetails.find((item) => item.name === "Silver")?.price || "";
        const platinum = data.productPriceDetails.find((item) => item.name === "Platinum")?.price || "";
  
        const newPreviousData = {
          date: data.updatedDate || "",
          gold: gold,
          silver: silver,
          platinum: platinum,
          previousDate: data.lastUpdatedDate || "",
          previousGold: data.previousProductPriceDetails.find((item) => item.name === "Gold")?.price || "",
          previousSilver: data.previousProductPriceDetails.find((item) => item.name === "Silver")?.price || "",
          previousPlatinum: data.previousProductPriceDetails.find((item) => item.name === "Platinum")?.price || "",
          previousTemplate: data.template || "",
        };
  
        setGoldRate(gold);
        setSilverRate(silver);
        setPlatinumRate(platinum);
        setDate(new Date(data.updatedDate));
        setTemplate(data.template); 
        console.log("stored-template-until-the-new-get-response-received:",data.template)
        setPreviousData(newPreviousData);
  
        
        localStorage.setItem("getresponseTemplate", data.template || storedTemplate);
        localStorage.setItem("previousData", JSON.stringify(newPreviousData));
  
            dispatch(hideLoading())
        
        //  dispatch(hideLoading());
      })
      .catch((error) => {
        toast.error(error.message);
        if (storedTemplate) setTemplate(storedTemplate);
        if (storedPreviousData) setPreviousData(JSON.parse(storedPreviousData));
       //   dispatch(hideLoading());
       dispatch(hideLoading())
      });
  }, []);
  useEffect(() => {

    setEditableRate(null);
  }, [date]);

  const handleEdit = (rate) => {
    setEditableRate((prevEditableRate) =>
      prevEditableRate === rate ? null : rate
    );
  };
  const handleInputChange = (e, setter) => {
    const { value } = e.target;

    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;

    if (regex.test(value) || value === "") {
      setter(value);
    }
  };

  const createNewObjectWithoutValues = (obj) => {
   
    const keys = Object.keys(obj);

   
    const newObj = {};
    keys.forEach((key) => {
      newObj[key] = "";
    });

    return newObj;
  };

  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };

const getTemplate = (date, gold, silver, platinum) => {
  return `${date.toLocaleDateString("en-GB")} ${getDayOfWeek(date)}\nGOLD RATE: ${gold}\nSILVER RATE: ${silver}\nPLATINUM RATE: ${platinum}\nRam Thanga Nagai Maligai, Puducherry.`;
};

const handleSubmit = (e) => {
  e.preventDefault();
   dispatch(showLoading());

 const previousTemplate = processTemplate(
  template,
  date,
  goldRate,
  silverRate,
  platinumRate
);


const selectedDate = previousData.date ? previousData.date : date.toLocaleDateString("en-GB");
const newPreviousData = {
  date: new Date().toISOString(), 
  gold: goldRate,
  silver: silverRate,
  platinum: platinumRate,
  previousDate: previousData.date, 
  previousGold: previousData.gold,
  previousSilver: previousData.silver,
  previousPlatinum: previousData.platinum,
  previousTemplate: previousData.previousTemplate
};


  localStorage.setItem("previousData", JSON.stringify(newPreviousData));
 
  console.log("newPreviousData",newPreviousData);
  setPreviousData(newPreviousData);


  const postData = {
    productPriceDetails: [
      { id: 1, name: "Gold", price: parseFloat(goldRate) || 0 },
      { id: 2, name: "Silver", price: parseFloat(silverRate) || 0 },
      { id: 3, name: "Platinum", price: parseFloat(platinumRate) || 0 },
    ],
    templateId: 1,
    selectedDate: date.toISOString(),
  };

  const token = localStorage.getItem("accessToken");
  if (!token) {
    toast.error("Access token not found in localStorage");
    //  dispatch(hideLoading());
    return;
  }

  
  fetch(`${process.env.REACT_APP_BASE_URL}prices/updateprice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .then((data) => {
     
      handleSuccess();
        dispatch(showLoading());
      if (data.template) {
        setTemplate(data.template);
        localStorage.setItem("getresponseTemplate", data.template);
      }
      
      setTimeout(() => {
        dispatch(hideLoading());  
      
      }, 2000);
    })
    .catch((error) => {
     // toast.error(error.message);
     //alert(error.message)
        dispatch(hideLoading());
    });
};

const smsCharacterCount = processTemplate(
  previousData.previousTemplate, 
          date,
          goldRate,
          silverRate,
          platinumRate
).length;

  const handleDatePickerClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (selectedDate) => {
    setGoldRate("");
    setSilverRate("");
    setPlatinumRate("");
    setDate(selectedDate);
    setShowDatePicker(false);
  };
  const handleCancel = () => {
    setGoldRate(previousData.gold || "");
    setSilverRate(previousData.silver || "");
    setPlatinumRate(previousData.platinum || "");
    setDate(new Date(previousData.date));
  };
 


  return (
    
    <div id="priceoutercont">
     <Snackbar
  open={successMessage}
  autoHideDuration={4000}
  onClose={() => setSuccessMessage(false)} 
  anchorOrigin={{ vertical: "top", horizontal: "center" }}
>
  <Alert
    onClose={() => setSuccessMessage(false)}
    severity={messageType} 
    sx={{ width: "100%" , height:"3.5rem", alignItems:"center",display:"flex",textAlign:"center", fontWeight:"600", fontSize:"16px"}}
  >
    {message} 
  </Alert>
</Snackbar>

  
    <div id="rtmprice">RTM Gold Price Updates</div>
   {/*   {loading && (
      <div className="overlay">
        <div className="spinner"></div>
      </div>
    )}  */}
   
    <div id="realtime">
      Stay informed with real-time rates of gold, silver, and platinum.
      Customize your SMS notifications for seamless updates.
    </div>
    <div id="smswhole-outer">
    <div id="smswhole">
      <div id="staydetail">
        <table>
          <tbody>
            <tr>
              <td className="icon-cell">
                <img src={data_icon} alt="Date Icon" className="dateicon" />
              </td>
              <td colSpan="2">
                <div className="date-container">
                  <span className="todaydate">Today's Date:</span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" onClick={handleDatePickerClick}>
                <div id="backgrddate">
                  <span className="date-value">
                    {date.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </span>
                  <img
                    src={calender_icon}
                    alt="Calender Icon"
                    className="calicon"
                    onClick={handleDatePickerClick}
                  />
                </div>
              </td>
            </tr>
            {showDatePicker && (
              <tr>
                <td colSpan="3" className="datepicker-cell">
                  <DatePicker className ="datepicker-cell-outer"
                    selected={date}
                    onChange={handleDateChange}
                    inline={true}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    maxDate={new Date()}
                    onClickOutside={() => setShowDatePicker(false)}
                    onSelect={() => setShowDatePicker(false)}
                  />
                </td>
              </tr>
            )}
            <tr>
              <td className="icon-cell1">
                <img src={goldicon} alt="Gold Icon" className="goldicon" />
              </td>
              <td colSpan="2">
                <div className="gold-container">
                  <span className="goldrate">Gold Rate</span>
                  <img
                    src={valueedit}
                    alt="Edit Icon"
                    className="editicon"
                    onClick={() => handleEdit("gold")}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className={editableRate === "gold" ? "editable" : ""}>
                {editableRate === "gold" ? (
                  <input
                    type="text"
                    id="goldRate"
                    value={goldRate}
                    onChange={(e) => handleInputChange(e, setGoldRate)}
                    className="gold-input"
                  />
                ) : (
                  <span
                    className="gold-value"
                    onClick={() => handleEdit("gold")}
                  >
                    <span className="rupees-symbol">&#8377;</span>{" "}
                    {goldRate}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="icon-cell2">
                <img src={silvericon} alt="Silver Icon" className="silvericon" />
              </td>
              <td colSpan="2">
                <div className="silver-container">
                  <span className="silverrate">Silver Rate</span>
                  <img
                    src={valueedit}
                    alt="Edit Icon"
                    className="editiconsilver"
                    onClick={() => handleEdit("silver")}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className={editableRate === "silver" ? "editable" : ""}>
                {editableRate === "silver" ? (
                  <input
                    type="text"
                    id="silverRate"
                    value={silverRate}
                    onChange={(e) => handleInputChange(e, setSilverRate)}
                    className="silver-input"
                  />
                ) : (
                  <span
                    className="silver-value"
                    onClick={() => handleEdit("silver")}
                  >
                    <span className="rupees-symbol">&#8377;</span>{" "}
                    {silverRate}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="icon-cell3">
                <img src={platinumicon} alt="Platinum Icon" className="platinumicon" />
              </td>
              <td colSpan="2">
                <div className="platinum-container">
                  <span className="platinumrate">Platinum Rate</span>
                  <img
                    src={valueedit}
                    alt="Edit Icon"
                    className="editiconplatinum"
                    onClick={() => handleEdit("platinum")}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className={editableRate === "platinum" ? "editable" : ""}>
                {editableRate === "platinum" ? (
                  <input
                    type="text"
                    id="platinumRate"
                    value={platinumRate}
                    onChange={(e) => handleInputChange(e, setPlatinumRate)}
                    className="platinum-input"
                  />
                ) : (
                  <span
                    className="platinum-value"
                    onClick={() => handleEdit("platinum")}
                  >
                    <span className="rupees-symbol">&#8377;</span>{" "}
                    {platinumRate}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="smsraterow">
        <div id="yestcont">
          <div className="yestconttitle">Previous Rate</div>
          <div id="table-container">
            <table className="tabular-column">
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>
  {previousData.previousDate 
    ? new Date(previousData.previousDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }) 
    : ""}
</td>
                </tr>
                <tr>
                  <td>Gold</td>
                  <td>{previousData.previousGold}</td>
                </tr>
                <tr>
                  <td>Silver</td>
                  <td>{previousData.previousSilver}</td>
                </tr>
                <tr>
                  <td>Platinum</td>
                  <td>{previousData.previousPlatinum}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="smscont">
          <div id="smstem">SMS Template</div>
          <br />
          <div id="smsdetail">
           
          
            <div>
            {processTemplate(
              previousData.previousTemplate || localStorage.getItem("getresponseTemplate") || '',
              date,
              goldRate,
              silverRate,
              platinumRate
            )}
        </div>
          {/*  <div>Give a missed call 9361111623 to get the rate.</div> */}
          </div>
          <div id="allcount">
      <div id="chatcount">
     {previousData.previousTemplate.length}/160 characters
     </div>
  <     div id="mescount">
        {smsCharacterCount <= 160
        ? "No of messages: 1"
        : `No of messages: ${Math.ceil(smsCharacterCount / 160)}`}
            </div>
          </div>
        
                  </div>
        <React.Fragment>
            <button
              type="submit"
              onClick={handleSubmit}
              className="save-button"
            >
              Save
            </button>
            <button
              type="cancel"
              onClick={handleCancel}
              className="cancel-button"
            >
              Cancel
            </button>
          </React.Fragment>
          </div>
      </div>
    </div>
  </div>
);
};

export default PriceUpdates;

