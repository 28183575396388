import React, { useState, useEffect } from 'react';
import Odometer from 'react-odometerjs';
import './MissedCallCounter.css';
import { HubConnectionBuilder } from '@microsoft/signalr';

const MissedCallCounter = () => {
  const [todaysMissed, setTodaysMissed] = useState(8888); 
  const [yesterdaysMissed, setYesterdaysMissed] = useState(0); 
  const [connection, setConnection] = useState(null); 

  useEffect(() => {
    const fetchInitialCounts = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}missedcalllogs`, {
         headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }
        const data = await response.json();
        setTodaysMissed(data.todayCount || 0);
        setYesterdaysMissed(data.yesterdayCount || 0);  
      } catch (err) {
        console.error('Error fetching initial missed call counts:', err);
      }
    };
    const setupSignalRConnection = async () => {
      try {
       // const token = localStorage.getItem("accessToken");
        const newConnection = new HubConnectionBuilder()

            .withUrl(`${process.env.REACT_APP_BASE_URL}missedcallcounthub`, {
            skipNegotiation: true, 
            transport: 1,  
            //accessTokenFactory: () => token,       
          })
          .withAutomaticReconnect()
          .build();

        await newConnection.start();
        console.log('Connected to SignalR hub');
        setConnection(newConnection);

        newConnection.on('MissedCallMetricsCount', (count) => {
          if (typeof count === 'number') {
            console.log('Missed call count received:', count);
            setTodaysMissed(count);  
          } else {
            console.error('Invalid data type for received missed call count:', count);
          }
        });
      } catch (err) {
        console.error('Error setting up SignalR connection:', err);
      }
    };

    fetchInitialCounts();
    setupSignalRConnection();

    return () => {
      if (connection) {
        connection
          .stop()
          .then(() => console.log('SignalR connection stopped'))
          .catch((err) => console.error('Error stopping SignalR connection:', err));
      }
    };
  }, []);

  const renderDigitsWithRectangles = (number) => {
    return number
      .toString()
      .padStart(4, '0') 
      .split('')
      .map((digit, index) => (
        <div key={index} className="digit-rectangle">
          {digit}
        </div>
      ));
  };

  return (
    <div className="missed-call-counter-container">
      <div className="missed-call yesterdays-missed">
        <p>Yesterday's Missed Calls</p>
        <div className="static-count yesterdays-static">
          {renderDigitsWithRectangles(yesterdaysMissed)} 
        </div>
      </div>
      <div className="missed-call-today todays-missed">
        <p>Today's Missed Calls</p>
        <div className="odometer-container todays-odometer">
          <Odometer value={todaysMissed.toString().padStart(4, '0')} format="(ddd),dd" />
        </div>
      </div>
    </div>
  );
};

export default MissedCallCounter;
