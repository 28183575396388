import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import "./NewTemplate.css";
import { useDispatch} from 'react-redux';
import { showLoading, hideLoading } from '../redux/slices/loadingSlice';
const ActivateTemplate = () => {
  const templateNameRef = useRef(null);
  const smsTemplateRef = useRef(null);
 const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [smsTemplate, setSmsTemplate] = useState("");

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("accessToken");
         dispatch(showLoading())
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}template/templateList`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data.templates)) {
          setTemplates(response.data.templates);
        } else {
          setTemplates([]);
          toast.error("No templates available.", {
            style: { background: "#fef2f2", color: "#b91c1c" },
          });
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
        toast.error("Failed to fetch templates. Please try again later.", {
          style: { background: "#fef2f2", color: "#b91c1c" },
        });
      } finally {
        dispatch(hideLoading());
      }
    };
    fetchTemplates();
  }, []);

  const handleTemplateChange = (e) => {
    const value = e.target.value;
    if (value === "default") {
      setSelectedTemplate(null);
      setTemplateName("");
      setSmsTemplate("");
    } else {
      const selected = templates.find((template) => template.templateId === value);
      setSelectedTemplate(selected || null);
      setTemplateName(selected?.name || "");
      setSmsTemplate(selected?.message || "");
    }
  };

  const validateForm = () => {
    if (!templateName.trim()) {
      toast.error("Template Name is required", {
        style: { background: "#fef2f2", color: "#b91c1c" },
      });
      return false;
    }
    if (!smsTemplate.trim()) {
      toast.error("SMS Template cannot be empty", {
        style: { background: "#fef2f2", color: "#b91c1c" },
      });
      return false;
    }
    return true;
  };
  //const handleSubmit = () => {
   // if (validateForm()) {
    //  console.log("Activated Template Name:", templateName);
    //  console.log("Activated SMS Template:", smsTemplate);
    //  toast.success("Template successfully activated!", {
    //    style: { background: "#ecfdf5", color: "#047857" },
   //   });
   //   setSelectedTemplate("default");
   //   setTemplateName("");
   //   setSmsTemplate("");
  //  }
 // };

  const handleSubmit = async () => {
    if (validateForm() && selectedTemplate) {
      const token = localStorage.getItem("accessToken");
      try {
        dispatch(showLoading())
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}template/activate?id=${selectedTemplate.id}`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
        
          setTemplates((prevTemplates) =>
            prevTemplates.map((template) =>
              template.id === selectedTemplate.id
                ? { ...template, activeStatus: true }
                : template
            )
          );
          toast.success(`Template "${templateName}" activated successfully!`, {
            style: { background: "#ecfdf5", color: "#047857" },
          });
        } else {
          toast.error("Failed to activate the template.", {
            style: { background: "#fef2f2", color: "#b91c1c" },
          });
        }
      } catch (error) {
        console.error("Error activating the template:", error);
        toast.error("An error occurred while activating the template.", {
          style: { background: "#fef2f2", color: "#b91c1c" },
        });
      } finally {
        dispatch(hideLoading());
      }
  
      setSelectedTemplate(null);
      setTemplateName("");
      setSmsTemplate("");
    }
  };

  const handleCancel = () => {
    setSelectedTemplate(null);
    setTemplateName("");
    setSmsTemplate("");
    toast("ℹ️ Action cancelled", {
      style: { background: "#eef2ff", color: "#4338ca" },
    });
  };

  const handleEditClick = (inputRef) => {
    inputRef.current.focus();
  };

  return (
    <div id="priceoutercont">
      <div id="rtmprice">RTM Activate Template</div>
      <div id="realtime">
        Easily select and activate your preferred RTM Template for customized SMS updates.
      </div>
      <div id="smswhole-newtem">
        <div className="form-group">
          <label>Template Selector</label>
          <select
            value={selectedTemplate?.templateId || "default"}
            onChange={handleTemplateChange}
            style={{ padding: "12px 20px", fontSize: "1rem", width: "100%" }}
          >
            <option value="default">Select a template</option>
            {templates.map((template) => (
              <option key={template.templateId} value={template.templateId}>
                {template.name || template.templateName}
              </option>
            ))}
          </select>
        </div>
        {selectedTemplate && (
          <>
            <div className="form-group">
              <label>Template Name</label>
              <input
                type="text"
                placeholder="Enter a new template name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                ref={templateNameRef}
                readOnly
                style={{
                  cursor: "not-allowed",
                }}
              />
            </div>
            <div className="form-group">
              <label>SMS Template</label>
              <textarea
                placeholder="SMS Template"
                value={smsTemplate}
                onChange={(e) => setSmsTemplate(e.target.value)}
                ref={smsTemplateRef}
                rows={10}
               
                style={{
                                  width: "100%",
                                   cursor: "not-allowed",
                }}
                readOnly
                
              ></textarea>
            </div>
            <div className="sms-button">
              <button className="save-btn" onClick={handleSubmit}>
                Activate
              </button>
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontFamily: "Lato, sans-serif",
            borderRadius: "8px",
          },
        }}
      />
    </div>
  );
};

export default ActivateTemplate;
