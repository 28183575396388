
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home, AccountBox, CurrencyExchange} from '@mui/icons-material';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import './Dashboard.css';
import panIcon from './picture/panicon.png';
import aadhaarIcon from './picture/aadhaaricon.png';
import dropdown from './picture/dropdown.png';
import pricejewel from './priceimage/pricejewel.png';
import homeicon from './picture/homeicon.png';
import pricejewelbl from './priceimage/pricejewelbl.png'; 
import homeblicon from './picture/homeblicon.png'; 
import templateIcon from './picture/template-icon.png'; 

const Dashboard = ({ hideIntroduction }) => {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState(null);
  const [isPANDropdownOpen, setPANDropdownOpen] = useState(false);
  const [isAadhaarDropdownOpen, setAadhaarDropdownOpen] = useState(false);
  const [isTemplateDropdownOpen, setTemplateDropdownOpen] = useState(false);
  const [panDropdownRotation, setPanDropdownRotation] = useState(0);
  const [aadhaarDropdownRotation, setAadhaarDropdownRotation] = useState(0);
  const [templateDropdownRotation, setTemplateDropdownRotation] = useState(0);
  const [isPriceUpdateButtonEnabled, setPriceUpdateButtonEnabled] = useState(false);
  const [isHomeButtonEnabled, setHomeButtonEnabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [newRotation, setIsRo] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const roleReceived = localStorage.getItem("roleReceived");

  const isAdmin = roleReceived === "Admin";
  useEffect(() => {
    const updateView = () => {
      setIsMobileView(window.innerWidth <= 768); 
    };

    updateView(); 
    window.addEventListener('resize', updateView);

    return () => {
      window.removeEventListener('resize', updateView);
    };
  }, []);
  
  useEffect(() => {
    if (location.pathname === "/introduction") {
      setHomeButtonEnabled(true);
    } else {
      setHomeButtonEnabled(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/introduction") {
      setSelectedLink(null);
    }
  }, [location.pathname]);
  
  useEffect(() => {
    const templateRoutes = [
      "/priceupdates",
      "/newtemplate",
      "/activatetemplate",
      "/managetemplate",
    ];
    if (templateRoutes.includes(location.pathname)) {
      setSelectedLink(location.pathname);
    } else if (location.pathname === "/pan360" || location.pathname === "/aadhaar360") {
      setSelectedLink(location.pathname);
    } else {
      setSelectedLink(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/priceupdates") {
      setPriceUpdateButtonEnabled(true);
    } else {
      setPriceUpdateButtonEnabled(false);
    }
  }, [location.pathname]);

  const togglePANDropdown = () => {
    setPANDropdownOpen(!isPANDropdownOpen);
    setPanDropdownRotation(isPANDropdownOpen ? 0 : 180);
  };

  const toggleAadhaarDropdown = () => {
    setAadhaarDropdownOpen(!isAadhaarDropdownOpen);
    setAadhaarDropdownRotation(isAadhaarDropdownOpen ? 0 : 180);
  };

  const toggleTemplateDropdown = () => {
    setTemplateDropdownOpen(!isTemplateDropdownOpen);
    const newRotation = !isTemplateDropdownOpen ? 180 : 0;
    setTemplateDropdownRotation(newRotation);
    console.log('Dropdown rotation: ', newRotation);
  };
  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  const handleLinkClick = (link) => {
    setSelectedLink(link);
    hideIntroduction();
  };

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  return (
    <div id="outerdashcont">
      <div className={`dashboard ${isExpanded ? 'expanded' : ''}`} onMouseEnter={handleExpand} onMouseLeave={handleCollapse}>
        <ul>
          <li>
            <Link to="/introduction" className={`hometext-style ${isHomeButtonEnabled ? 'enabled' : 'disabled'}`}>
              <div id="homeborder">
                <div className="hometext">
                  <img src={isHomeButtonEnabled ? homeblicon : homeicon} alt="Home Icon" className="homeicon" />
                  {isExpanded && <p>Home</p>}
                </div>
              </div>
            </Link>
          </li>
          <li   onClick={togglePANDropdown}>
                        <div className="dashboard-icon-container">
                        
              <img src={panIcon} alt="PAN Icon" className="dashboard-icon" />
              {isExpanded && <span>PAN</span>}
              {isExpanded && (
                <img
                  src={dropdown}
                  alt="Dropdown Icon"
                  className="dropdown-icon"
                  style={{ transform: `rotate(${panDropdownRotation}deg)` }}
                />
              )}
            </div>
            {isPANDropdownOpen && isExpanded && (
              <ul className="dropdown" onClick={handleDropdownClick}>
              
                <li>
                  
                            <Link 
                    to="/pan360"
                       className="pan360-link"
                    style={{
                      backgroundColor: selectedLink === "/pan360" ? "#FFD966" : "",
                      width: "120px",
                      borderRadius: "8px",
                      height: "40px",
                      position: "absolute",
                      display: "block",
                      top: "100%",
                      transform: "translate(0%, -50%)",
                      left: "50%",
                      textAlign: "center",
                      lineHeight: "40px",
                      marginTop: "25px",
                      marginLeft: "-57px",
                      fontWeight: selectedLink === "/pan360" ? "bold" : "",
                      color: selectedLink === "/pan360" ? "#000000" : "#ffffff",
                    }}
                    onClick={() => handleLinkClick("/pan360")}
                  >
                    PAN 360
                  </Link>
                </li>
              </ul>
            )}
          </li>



          <li onClick={toggleAadhaarDropdown}>
            <div className="dashboard-icon-container" >
              <img src={aadhaarIcon} alt="Aadhaar Icon" className="dashboard-icon" />
              {isExpanded && <span>Aadhaar</span>}
              {isExpanded && (
                <img
                  src={dropdown}
                  alt="Dropdown Icon"
                  className="dropdown-iconaadh"
                  style={{ transform: `rotate(${aadhaarDropdownRotation}deg)` }}
                />
              )}
            </div>
            {isAadhaarDropdownOpen && isExpanded && (
              <ul className="basic-validation-dropdown" onClick={handleDropdownClick}>
              
               
                <li>
           
                                                <Link
                    to="/aadhaar360"
                     className="pan360-link"
                                style={{
                      backgroundColor: selectedLink === "/aadhaar360" ? "#FFD966" : "",
                      width: "120px",
                      borderRadius: "8px",
                      height: "40px",
                      position: "absolute",
                      display: "block",
                      top: "100%",
                      transform: "translate(0%, -50%)",
                      left: "48%",
                      textAlign: "center",
                      lineHeight: "40px",
                      marginTop: "25px",
                      marginLeft: "-85px",
                      marginBottom: "50px", 
                      fontWeight: selectedLink === "/aadhaar360" ? "bold" : "",
                      color: selectedLink === "/aadhaar360" ? "#000000" : "#ffffff",
                    }}
                    onClick={() => handleLinkClick("/aadhaar360")}
                  >
                    Aadhaar 360
                                     </Link>
                 
                </li>
               
              </ul>
            )}
          </li>
          <li onClick={toggleTemplateDropdown} className="menu-item">
  <div className="dashboard-icon-container-template">
    <img src={templateIcon} alt="Template Icon" className="dashboard-icon" />
    {isExpanded && <span>Template</span>}
    {isExpanded && (
      <img
        src={dropdown}
        alt="Dropdown Icon"
        className="dropdown-icon-template"
        style={{ transform: `rotate(${templateDropdownRotation}deg)` }}
      />
    )}
  </div>
  {isTemplateDropdownOpen && isExpanded && (
    <ul className="dropdown" onClick={handleDropdownClick}>
         <div className='nav-list-outer'>
      <li>
    
      
        <Link
          to="/priceupdates"
         
          style={{
            backgroundColor: selectedLink === "/priceupdates" ? "#FFD966" : "",
            fontWeight: selectedLink === "/priceupdates" ? "bold" : "",
            color: selectedLink === "/priceupdates" ? "#000000" : "#ffffff",
          }}
          onClick={() => handleLinkClick("/priceupdates")}
        >
          Price Updates
        </Link>
        
      </li>
      {isAdmin && (
            <>
      <li>
    
        <Link
          to="/newtemplate"
          className="template-link"
          style={{
            backgroundColor: selectedLink === "/newtemplate" ? "#FFD966" : "",
            fontWeight: selectedLink === "/newtemplate" ? "bold" : "",
            color: selectedLink === "/newtemplate" ? "#000000" : "#ffffff",
          }}
          onClick={() => handleLinkClick("/newtemplate")}
        >
          New Template
        </Link>
       
      </li>
      <li>
        <Link
          to="/managetemplate"
          className="template-link"
          style={{
            backgroundColor: selectedLink === "/managetemplate" ? "#FFD966" : "",
            fontWeight: selectedLink === "/managetemplate" ? "bold" : "",
            color: selectedLink === "/managetemplate" ? "#000000" : "#ffffff",
          }}
          onClick={() => handleLinkClick("/managetemplate")}
        >
          Manage Template
        </Link>
      </li>

      <li>
    
        <Link
          to="/activatetemplate"
          className="template-link"
          style={{
            backgroundColor: selectedLink === "/activatetemplate" ? "#FFD966" : "",
            fontWeight: selectedLink === "/activatetemplate" ? "bold" : "",
            color: selectedLink === "/activatetemplate" ? "#000000" : "#ffffff",
          }}
          onClick={() => handleLinkClick("/activatetemplate")}
        >
          Activate Template
        </Link>
      
      </li>
      
      <li>
        <Link
          to="/managesms"
          className="template-link"
          style={{
            backgroundColor: selectedLink === "/managesms" ? "#FFD966" : "",
            fontWeight: selectedLink === "/managesms" ? "bold" : "",
            color: selectedLink === "/managesms" ? "#000000" : "#ffffff",
          }}
          onClick={() => handleLinkClick("/managesms")}
        >
          Manage SMS
        </Link>
      </li>
      </>
          )}
      </div>
    </ul>
  )}

</li>

  </ul>
  </div>
  {isMobileView && (
  <BottomNavigation
        value={location.pathname}
        onChange={(event, newValue) => setSelectedLink(newValue)}
        className="bottom-navigation"
      >
        <BottomNavigationAction
          label="Home"
          value="/introduction"
          icon={<Home />}
          component={Link}
          to="/introduction"
          onClick={() => handleLinkClick("/introduction")}
        />
        <BottomNavigationAction
          label="PAN"
          value="/pan360"
          icon={<AccountBox />}
          component={Link}
          to="/pan360"
          onClick={() => handleLinkClick("/pan360")}
        />
        <BottomNavigationAction
          label="Aadhaar"
          value="/aadhaar360"
          icon={<FingerprintIcon/>}
          component={Link}
          to="/aadhaar360"
          onClick={() => handleLinkClick("/aadhaar360")}
        />
        <BottomNavigationAction
          label="Price Up..."
          value="/priceupdates"
          icon={<CurrencyExchange />}
          component={Link}
          to="/priceupdates"
          onClick={() => handleLinkClick("/priceupdates")}
        />
      </BottomNavigation>
       )}
    </div>
  );
};

export default Dashboard;

