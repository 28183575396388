import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import EditOutline from './picture/edit-outline.svg';
import deleteSVG from './priceimage/delete.svg';
import deleteBin from './priceimage/del-bin.svg';
import { useDispatch} from 'react-redux';
import { showLoading, hideLoading } from '../redux/slices/loadingSlice';
const ManageTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [smsTemplate, setSmsTemplate] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
 const dispatch = useDispatch();
  const templateIdRef = useRef(null);
  const templateNameRef = useRef(null);
  const smsTemplateRef = useRef(null);

  useEffect(() => {
    
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("accessToken");
          dispatch(showLoading());
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}template/templateList`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, 
          },
        });
        
        setTemplates(response.data.templates);
      } catch (error) {
        toast.error('Failed to fetch templates. Please try again.', {
          style: { background: '#fef2f2', color: '#b91c1c' },
        });
      }
       setTimeout(() => {
           dispatch(hideLoading())
          }, 2000); 
    };
    fetchTemplates();
  }, []);

  const handleTemplateChange = (e) => {
    const value = e.target.value;
    setSelectedTemplate(value);

    if (value === 'default') {
      setTemplateId('');
      setTemplateName('');
      setSmsTemplate('');
    } else {
      const selected = templates.find((template) => template.templateId.toString() === value);
      if (selected) {
        setTemplateId(selected.templateId.toString()); 
        setTemplateName(selected.name || '');
        setSmsTemplate(selected.message || '');
      }
    }
  };
  const validateForm = () => {
    if (!templateId || templateId.toString().trim() === '') {
      toast.error('Template ID is required', {
        style: { background: '#fef2f2', color: '#b91c1c' },
      });
      return false;
    }
    if (!templateName.trim()) {
      toast.error('Template Name is required', {
        style: { background: '#fef2f2', color: '#b91c1c' },
      });
      return false;
    }
    if (!smsTemplate.trim()) {
      toast.error('SMS Template cannot be empty', {
        style: { background: '#fef2f2', color: '#b91c1c' },
      });
      return false;
    }
    return true;
  };
  
  //const handleSubmit = () => {
   // if (validateForm()) {
    //  console.log('Template ID:', templateId);
    //  console.log('Template Name:', templateName);
    //  console.log('SMS Template:', smsTemplate);
    //  toast.success('Template successfully updated!');
    //}
  //};

  const handleSubmit = async () => {
  if (validateForm()) {
    const selected = templates.find((template) => template.templateId.toString() === templateId);

    if (!selected) {
      toast.error('Invalid template selection. Please try again.');
      return;
    }

    const payload = {
      id: selected.id,
      clientId: 1,
      templateId: selected.templateId, 
      name: templateName,
      message: smsTemplate,
      deletedStatus: false,
      activeStatus: false,
    };

    console.log('Payload:', payload);

    try {
      const token = localStorage.getItem('accessToken');
      dispatch(showLoading());
      if (!token) {
        throw new Error('Access token not found. Please log in again.');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}template/updatetemplate?id=${selected.id}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('API Response:', response.data);

      toast.success('Template successfully updated!');
     
      setTemplates((prev) =>
        prev.map((template) =>
          template.id === selected.id
            ? { ...template, name: templateName, message: smsTemplate }
            : template
        )
      );

      
      setTemplateId('');
      setTemplateName('');
      setSmsTemplate('');
      setSelectedTemplate('default');
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      toast.error('Failed to update template. Please try again.');
    }
    setTimeout(() => {
      dispatch(hideLoading())
     }, 2000); 

  }
  
};


  const handleCancel = () => {
    setTemplateId('');
    setTemplateName('');
    setSmsTemplate('');
    toast('ℹ️ Form cleared', {
      style: { background: '#eef2ff', color: '#4338ca' },
    });
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      dispatch(showLoading());
     
      const templateToDelete = templates.find((template) => 
        template.templateId.toString() === templateId);
  
      if (!templateToDelete) {
        toast.error("Template not found. Please try again.");
        return;
      }
  
      
      await axios.delete(`${process.env.REACT_APP_BASE_URL}template/${templateToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      const updatedTemplates = templates.filter((template) => template.templateId !== Number(templateId));
      setTemplates(updatedTemplates);
  
      
      setSelectedTemplate("default");
      setTemplateId("");
      setTemplateName("");
      setSmsTemplate("");
      setShowDeletePopup(false);
  
      toast.success("Template deleted successfully!", {
        style: { background: "#ecfdf5", color: "#047857" },
      });
    } catch (error) {
      console.error("Error deleting template:", error);
  
      toast.error("Failed to delete the template. Please try again.", {
        style: { background: "#fef2f2", color: "#b91c1c" },
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleEditClick = (inputRef) => {
    inputRef.current.focus();
  };

  return (
    <div id="priceoutercont">
      <div id="rtmprice">RTM Manage Template</div>
      <div id="realtime">
        Easily edit or delete your existing RTM Templates to customize your daily SMS updates. Stay in charge of your preferences with seamless template management.
      </div>
      <div id="smswhole-newtem">
        <div className="form-group">
          <label>Template Selector</label>
          <select
  value={selectedTemplate}
  onChange={handleTemplateChange}
  style={{ padding: '12px 20px', fontSize: '1rem', width: '100%' }}
>
  <option value="default">Select a template</option>
  {templates.map((template) => (
    <option key={template.templateId} value={template.templateId}>
      {template.name}
    </option>
  ))}
</select>
        </div>

        {selectedTemplate !== 'default' && (
          <>
            <div className="form-group">
              <label>
                Template ID
                <div className="edit-icon-outer" onClick={() => handleEditClick(templateIdRef)}>
                  <h3>Edit</h3>
                  <img src={EditOutline} alt="Edit Icon" className="edit-icon-svg" />
                </div>
              </label>
              <input
                type="text"
                value={templateId}
                onChange={(e) => setTemplateId(e.target.value)}
                placeholder="Enter a template ID"
                ref={templateIdRef}
              />
            </div>
            <div className="form-group">
              <label>
                Template Name
                <div className="edit-icon-outer" onClick={() => handleEditClick(templateNameRef)}>
                  <h3>Edit</h3>
                  <img src={EditOutline} alt="Edit Icon" className="edit-icon-svg" />
                </div>
              </label>
              <input
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Enter a new template name"
                ref={templateNameRef}
              />
            </div>
            <div className="form-group">
              <label>
                SMS Template
                <div className="edit-icon-outer" onClick={() => handleEditClick(smsTemplateRef)}>
                  <h3>Edit</h3>
                  <img src={EditOutline} alt="Edit Icon" className="edit-icon-svg" />
                </div>
              </label>
              <textarea
                value={smsTemplate}
                onChange={(e) => setSmsTemplate(e.target.value)}
                placeholder="SMS Template"
                ref={smsTemplateRef}
                rows={10}
                style={{ width: '100%' }}
              ></textarea>
            </div>
            <div className="sms-button">
              <button className="save-btn" onClick={handleSubmit}>
                Update
              </button>
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
              <button className="del-btn" onClick={() => setShowDeletePopup(true)}>
                <img src={deleteSVG} alt="Delete Icon" className="del-icon-svg" />
              </button>
            </div>
          </>
        )}

        {showDeletePopup && (
          <div className="delete-popup">
            <div className="delete-popup-content">
              <img src={deleteBin} alt="Delete Bin" className="delete-popup-icon" />
              <p>Are you sure you want to delete this template?</p>
              <div className="popup-buttons">
                <button className="delete-confirm-btn" onClick={handleDelete}>
                  Delete
                </button>
                <button className="cancel-confirm-btn" onClick={() => setShowDeletePopup(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontFamily: 'Lato, sans-serif',
            borderRadius: '8px',
          },
        }}
      />
    </div>
  );
};

export default ManageTemplate;
